import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiDeleteTask = (pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    pk?: number
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk: number | undefined
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/my_projects/tasks/${pk}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { pk?: number }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                pk: pk,
            }),
        })
}


export default useApiDeleteTask;