import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";

import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import {IDailyWorkLogTotal} from "../../../../../utils/interfaces/my_projects/IDailyWorkLogTotal";
import useApiGetDailyTotalsWorkLogs from "./api-hooks/useApiGetDailyTotalsWorkLogs";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {TbSum} from "react-icons/tb";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import WorkLogsTable from "./work_logs/WorkLogsTable";
import AddIcon from "@mui/icons-material/Add";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import WorkLogsForm from "./work_logs/WorkLogsForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";


interface Props {
    onSelect?: (obj: IDailyWorkLogTotal | undefined) => void,
    onClose?: () => void;
}


const DailyWorkLogsTotalsTable = ({onSelect, onClose}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IDailyWorkLogTotal | undefined>()
    const [rows, setRows] = useState<IDailyWorkLogTotal[] | undefined>()
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetDailyTotalsWorkLogs();


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IDailyWorkLogTotal, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IDailyWorkLogTotal>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IDailyWorkLogTotal>[] = [
                // {
                //     id: "icons",
                //     header: "ICONS",
                //     columnDefType: "display",
                //     Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
                // },
                {
                    accessorKey: 'date',
                    header: t('data'),
                    accessorFn: (row) => formatDateTimeToDateString(row.date),
                },
                {
                    accessorKey: 'total_hours_spent',
                    header: t('total hours spent'),
                    enableSorting: false,
                },
            ];

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IDailyWorkLogTotal>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <TbSum color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'Daily Totals'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        ),

        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('work logs'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            (
                <MuiBorderedBox>
                    <WorkLogsTable day={row.original.date}/>
                </MuiBorderedBox>
            )
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }
                <MaterialReactTable table={table}/>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >
                    <WorkLogsForm
                        objToEdit={null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default DailyWorkLogsTotalsTable;
