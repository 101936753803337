import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useQueryClient} from "@tanstack/react-query";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Avatar, Box, Grid} from "@mui/material";
import {FaRaspberryPi} from "react-icons/fa";
import {RaspberryPiPin} from "../../../../../../../utils/interfaces/industry_machine/RaspberryPiPinLog/RaspberryPiPin";
import useApiGetRaspberryPiIpPins from "./api-hooks/useApiGetRaspberryPiIpPins";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import Loading from "../../../../../../ui/Loading";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFetchingSpinner from "../../../../../../ui/mui/MuiFetchingSpinner";
import MuiBorderedBox from "../../../../../../ui/mui/MuiBorderedBox";
import RaspberryPiPinLogTable from "./raspberry_pi_pin_log/RaspberryPiPinLogTable";

interface Props {
    onSelect?: (obj: RaspberryPiPin | undefined) => void,
    onClose?: () => void;
    alreadySelectedObj?: RaspberryPiPin | undefined,
    ip: string
}


const RaspberryPiPinTable = ({onSelect, onClose, ip}: Props) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<RaspberryPiPin | undefined>()
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [rows, setRows] = useState<RaspberryPiPin[] | undefined>()


    const {
        data,
        isLoading,
        error,
        isFetching,
    } = useApiGetRaspberryPiIpPins(ip);


    useEffect(() => {
        if (data) {
            // const updatedData = data.map((row) => {
            //     if (row.pin_description === "CALDAIA") {
            //         return {
            //             ...row,
            //             pin_value: row.pin_value === 0 ? 1 : 0,
            //         };
            //     }
            //     return row;
            // });
            // setRows(updatedData);
            setRows(data)
        }
    }, [data]);

    const CustomCellRenderer: React.FC<{ row: RaspberryPiPin, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<RaspberryPiPin>[]>(
        () => [
            {
                id: "icons",
                header: "",
                columnDefType: "display",
                Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
            },

            {
                accessorKey: 'pin_description',
                header: ('sensore'),
                filterFn: 'contains',
            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<RaspberryPiPin>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows && rows.length > 10,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaRaspberryPi color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'PIN'}/>
                </>
            </Box>),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: ('log'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            (
                <MuiBorderedBox>
                    <RaspberryPiPinLogTable ip={row.original.raspberry_pi_ip} pin={row.original.pin_number}/>
                </MuiBorderedBox>
            )
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>}

                <MaterialReactTable table={table}/>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default RaspberryPiPinTable;
