import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    RaspberryPiPin
} from "../../../../../../../../utils/interfaces/industry_machine/RaspberryPiPinLog/RaspberryPiPin";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiGetRaspberryPiIpPins = (ip: string | undefined): UseQueryResult<RaspberryPiPin[], CustomAxiosError> => {
    const {get} = useApiCalls()
    const {selectedCompany} = useAuth()

    async function doGet(): Promise<RaspberryPiPin[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/industry_machine/raspberrypi_pin_log/raspberry_pi_ip/${ip}/pin`);
            return response.data

        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRaspberryPiIpPins"],
        queryFn: () => doGet(),
        enabled: !!ip,
    })
};


export default useApiGetRaspberryPiIpPins