import React, {useEffect, useState} from 'react';
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {IProject, IProjectCHILD} from "../../../../../utils/interfaces/my_projects/IProject";
import useApiGetProjects from "./api-hooks/useApiGetProjects";
import useApiGetProject from "./api-hooks/useApiGetProject";
import ProjectForm from "./ProjectForm";
import ProjectsTable from "./ProjectsTable";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";


interface Props {
    obj: IProject | IProjectCHILD | undefined,
    onChange: (obj: IProject | IProjectCHILD | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    withDefaultValueIfEmpty?: boolean
    person: IPeople | undefined
    isDisabled?: boolean
}

const ProjectSelect = ({
                           obj: initialObj,
                           label,
                           onChange,
                           required,
                           withDefaultValueIfEmpty = false,
                           person,
                           isDisabled
                       }: Props) => {


    const theme = useTheme();
    const {t} = useTranslation()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)
    const [isCurrentInUserWasteCodeCardIsOpen, setIsCurrentInUserWasteCodeCardIsOpen] = useState(false)

    const [objs, setObjs] = useState<IProject[]>([]);
    const [obj, setObj] = useState<IProject | IProjectCHILD | undefined>(initialObj)

    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
    } = useApiGetProjects(person);


    useEffect(() => {
        if (data) {
            setObjs(data)
            if (!initialObj && data.length > 0 && withDefaultValueIfEmpty) {
                setObj(data[0]);
                onChange(data[0]);
            }
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetProject(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IProject | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    const handleChange = (obj: IProject | undefined) => {
        setObj(obj);
        onChange(obj)
    }

    useEffect(() => {
        onChange(obj);

    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="site-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('recipient')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                            isDisabled={isDisabled}
                        >


                            <MRTMuiButton
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsListTableOpen(true)}
                                small={true}
                                label={'show_list'}
                            />
                            <MRTMuiButton
                                icon={obj ? <Edit sx={{color: theme.palette.primary.main}}/> :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsEditFormOpen(true)}
                                small={true}
                                label={obj ? 'edit' : 'add'}
                            />
                        </MuiAutocomplete>
                    </Box>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'50vh'}
                        open={isEditFormOpen}
                        onClose={() => setIsEditFormOpen(false)}
                    >
                        <ProjectForm objToEdit={obj ? obj : null}
                                     onSelect={onChange}
                                     onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>

                    <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                              onClose={() => setIsListTableOpen(false)}
                              disableBackdropClick={true}
                    >
                        <ProjectsTable
                            onSelect={handleChange}
                            onClose={() => setIsListTableOpen(false)}
                        />
                    </MuiModal>
                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'80vh'}
                        open={isCurrentInUserWasteCodeCardIsOpen}
                        disableBackdropClick={true}
                        onClose={() => setIsCurrentInUserWasteCodeCardIsOpen(false)}
                    >
                    </MuiModal>
                </>
            )}
        </>
    );
}

export default ProjectSelect;