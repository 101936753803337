import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import LoginScreen from '../../components/Login/LoginScreen';
import LogOutScreen from '../../components/Login/LogOutScreen';
import ProtectedRoute from './ProtectedRoute';
import HomeScreen from "../../components/app/Home/HomeScreen";
import PeopleScreen from "../../components/app/apps/service/people/PeopleScreen";
import OrderScheduleScreen from "../../components/app/apps/tannery_production/order_schedule/OrderScheduleScreen";
import LwgSitesScreen from "../../components/app/apps/tannery_raw_material/lwg_sites/LwgSitesScreen";
import LotsScreen from "../../components/app/apps/tannery_raw_material/lots/LotsScreen";
import WorkingsScreen from "../../components/app/apps/tannery_production/order_schedule/workings/WorkingsScreen";
import TanneryDyeingScheduleScreen
    from "../../components/app/apps/tannery_dyeing/tannery_dyeing_schedule/TanneryDyeingScheduleScreen";
import LwgProductionReportScreen
    from "../../components/app/apps/lwg_dashboard/lwg_reports/production/LwgProductionReportScreen";
import LwgWorkingsReportScreen
    from "../../components/app/apps/lwg_dashboard/lwg_reports/workings/LwgWorkingsReportScreen";
import DocScreen from "../../components/app/apps/my_documents/doc/DocScreen";
import ChemicalProductsScreen
    from "../../components/app/apps/chemicals_management/chemical_products/ChemicalProductsScreen";
import BasePage from "../../components/ui/layout/BasePage/BasePage";
import PeopleArticlesScreen from "../../components/app/apps/tannery_production/people_articles/PeopleArticlesScreen";
import MyCompanyScreen from "../../components/app/apps/my_company/MyCompanyScreen";
import MyWarehouseScreen from "../../components/app/apps/my_warehouse/MyWarehouseScreen";
import RicevuteBancarieScreen from "../../components/app/apps/my_documents/ricevute_bancarie/RicevuteBancarieScreen";
import FiscalRegistryScreen from "../../components/app/apps/my_documents/fiscal_registry/FiscalRegistryScreen";
import WastyRecipientScreen from "../../components/app/apps/special_wasty/wasty_recipient/WastyRecipientScreen";
import WastyCarrierScreen from "../../components/app/apps/special_wasty/wasty_carrier/WastyCarrierScreen";
import WasteCodeScreen from "../../components/app/apps/special_wasty/waste_code/WasteCodeScreen";
import WasteOpenLoadsScreen from "../../components/app/apps/special_wasty/waste_open_loads/WasteOpenLoadsScreen";
import WastyIntermediaryScreen
    from "../../components/app/apps/special_wasty/wasty_intermediary/WastyIntermediaryScreen";
import WasteMovementsScreen from "../../components/app/apps/special_wasty/waste_movements/WasteMovementsScreen";
import SpecialWastyScreen from "../../components/app/apps/special_wasty/SpecialWastyScreen";
import ProjectsScreen from "../../components/app/apps/my_projects/projects/ProjectsScreen";
import DailyWorkLogsTotalsScreen
    from "../../components/app/apps/my_projects/daily_work_logs_totals/DailyWorkLogsTotalsScreen";
import IndustryMachineScreen from "../../components/app/apps/industry_machine/IndustryMachineScreen";
import RaspberryPiIpScreen
    from "../../components/app/apps/industry_machine/raspberry_pi_pin_log/raspberry_pi_ip/RaspberryPiIpScreen";


const Navigation = () => {

    return (
        <Routes>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/logout" element={<LogOutScreen/>}/>
            <Route path="/" element={<LoginScreen/>}/>
            {/*<Route path="/" element={<WelcomeScreen/>}/>*/}

            <Route path="/" element={<BasePage/>}>
                <Route path="/apps/my_company/registry" element={
                    <ProtectedRoute>
                        <MyCompanyScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/lwg_dashboard/production_report" element={
                    <ProtectedRoute>
                        <LwgProductionReportScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/lwg_dashboard/workings_report" element={
                    <ProtectedRoute>
                        <LwgWorkingsReportScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_raw_material/lwg_sites" element={
                    <ProtectedRoute>
                        <LwgSitesScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/service/people" element={
                    <ProtectedRoute>
                        <PeopleScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/my_documents/doc" element={
                    <ProtectedRoute>
                        <DocScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_documents/fiscal_registry" element={
                    <ProtectedRoute>
                        <FiscalRegistryScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_documents/ricevute_bancarie" element={
                    <ProtectedRoute>
                        <RicevuteBancarieScreen/>
                    </ProtectedRoute>
                }/>


                <Route path="/apps/tannery_raw_material/people" element={
                    <ProtectedRoute>
                        <PeopleScreen category_person={'supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_raw_material/lots" element={
                    <ProtectedRoute>
                        <LotsScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_production/articles" element={
                    <ProtectedRoute>
                        <PeopleArticlesScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/order_schedule" element={
                    <ProtectedRoute>
                        <OrderScheduleScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/people" element={
                    <ProtectedRoute>
                        <PeopleScreen category_person={'subcontractor'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/workings" element={
                    <ProtectedRoute>
                        <WorkingsScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_dyeing/dyeing_schedule/people" element={
                    <ProtectedRoute>
                        <PeopleScreen isService={false} category_person={'chemical_supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/current" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'current'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/history" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'historical'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/drafts" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'drafts'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_raw_material/lwg_sites" element={
                    <ProtectedRoute>
                        <LwgSitesScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/chemicals_management/products" element={
                    <ProtectedRoute>
                        <ChemicalProductsScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/chemicals_management/people" element={
                    <ProtectedRoute>
                        <PeopleScreen isService={false} category_person={'chemical_supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_warehouse" element={
                    <ProtectedRoute>
                        <MyWarehouseScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty" element={
                    <ProtectedRoute>
                        <SpecialWastyScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty/wasty_recipient" element={
                    <ProtectedRoute>
                        <WastyRecipientScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty/wasty_intermediary" element={
                    <ProtectedRoute>
                        <WastyIntermediaryScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty/wasty_carrier" element={
                    <ProtectedRoute>
                        <WastyCarrierScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty/waste_code" element={
                    <ProtectedRoute>
                        <WasteCodeScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/special_wasty/waste_open_loads" element={
                    <ProtectedRoute>
                        <WasteOpenLoadsScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/special_wasty/waste_movements" element={
                    <ProtectedRoute>
                        <WasteMovementsScreen/>
                    </ProtectedRoute>
                }/>


                <Route path="/apps/my_projects/projects" element={
                    <ProtectedRoute>
                        <ProjectsScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_projects/work_logs" element={
                    <ProtectedRoute>
                        <DailyWorkLogsTotalsScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/industry_machine" element={
                    <ProtectedRoute>
                        <IndustryMachineScreen/>
                    </ProtectedRoute>
                }/>


                <Route path="/apps/industry_machine/raspberry_pi" element={
                    <ProtectedRoute>
                        <RaspberryPiIpScreen/>
                    </ProtectedRoute>
                }/>


                <Route path="/home" element={
                    <ProtectedRoute>
                        <HomeScreen/>
                    </ProtectedRoute>
                }/>

            </Route>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    );
};

export default Navigation;