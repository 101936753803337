import React from 'react';
import {Box, Tooltip, useTheme} from "@mui/material";

// Define reusable tooltip component props
interface CustomTooltipProps {
    title?: string; // Text to display in the tooltip (optional)
    children: React.ReactNode; // The element wrapped by the tooltip
}

// Reusable tooltip component
const CustomTooltip: React.FC<CustomTooltipProps> = ({title, children}) => {
    const theme = useTheme();

    return (
        <Tooltip
            title={
                title ? ( // Render only if title is defined
                    <Box sx={{fontSize: "0.8rem", color: "white", fontWeight: "bold"}}>
                        {title}
                    </Box>
                ) : ""
            }
            arrow
            placement="top"
            disableHoverListener={!title} // Disable if title is undefined
            sx={{
                tooltip: {
                    backgroundColor: theme.palette.primary.main, // Tooltip background color
                    borderRadius: "4px",
                    padding: "4px 8px",
                },
            }}
        >
            <span>
                {children}
            </span>
        </Tooltip>
    );
};

export default CustomTooltip;