import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IWorkLog} from "../../../../../../utils/interfaces/my_projects/IWorkLog";
import {IProjectCHILD} from "../../../../../../utils/interfaces/my_projects/IProject";
import {ITaskCHILD} from "../../../../../../utils/interfaces/my_projects/ITask";
import useApiCreateWorkLog from "./api-hooks/useApiCreateWorkLog";
import useApiUpdateWorkLog from "./api-hooks/useApiUpdateWorkLog";
import useApiGetWorkLog from "./api-hooks/useApiGetWorkLog";
import ProjectSelect from "../../projects/ProjectSelect";
import TaskSelect from "../../projects/tasks/TaskSelect";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import {getCurrentDate} from "../../../../../../logics/helpers";
import {IPeople, IPeopleCHILD} from "../../../../../../utils/interfaces/service/people/IPeople";
import PeopleSelect from "../../../service/people/PeopleSelect";
import useApiGetPersonDetails from "../../../service/people/api-hooks/useApiGetPersonDetails";


interface Props {
    objToEdit: IWorkLog | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IWorkLog | undefined) => void,
}

const WorkLogsForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {selectedCompany} = useAuth()
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();

    const [personDetailed, setPersonDetailed] = useState<IPeople | undefined>()
    const [person, setPerson] = useState<IPeopleCHILD | undefined>()
    const [project, setProject] = useState<IProjectCHILD>();
    const [task, setTask] = useState<ITaskCHILD>();
    const [date, setDate] = useState<string>(getCurrentDate());
    const [hoursSpent, setHoursSpent] = useState<number>();
    const [notes, setNotes] = useState<string>();

    const createMutation = useApiCreateWorkLog();
    const updateMutation = useApiUpdateWorkLog();

    const {
        data: detailedObj,
    } = useApiGetWorkLog(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setTask(detailedObj.task);
            setProject(detailedObj.task.project);
            setPerson(detailedObj.task.project.people)
            setDate(detailedObj.date)
            setHoursSpent(detailedObj.hours_spent)
            setNotes(detailedObj.notes)
        }
    }, [detailedObj]);

    const {
        data: detailedPerson
    } = useApiGetPersonDetails(selectedCompany?.company_token, person?.id)


    const handleSubmit = async () => {
        try {
            const payload = {
                task_id: task?.id,
                date: date || null,
                hours_spent: hoursSpent ? hoursSpent : null,
                notes: notes ? notes : null,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ['useApiGetWorkLog', newData?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWorkLogs", newData.date]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDailyWorkLogsTotals"]});
        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>
            <PeopleSelect
                label={'Person'}
                people={personDetailed}
                onChange={(obj) => {
                    if (obj) {
                        setPersonDetailed(obj)
                    }
                }}
                className={''}
                category_person={'customer'}/>
            <ProjectSelect
                label={'Project'}
                person={personDetailed}
                obj={project}
                onChange={(obj) => {
                    setProject(obj)
                }}
                className={''}
                isDisabled={!personDetailed}
            />
            <TaskSelect
                project={project ? project : undefined}
                className={''}
                label={'Task'}
                obj={task}
                onChange={(obj) => {
                    setTask(obj)
                }}
            />

            <MuiInputTextField
                onChange={(e) => setHoursSpent(parseFloat(e.target.value))}
                label='hours spent'
                type="number"
                value={hoursSpent}
                required={true}
            />
            <MuiInputTextField
                onChange={setDate}
                label='date'
                type="date"
                value={date}
                required={true}
            />

            <MuiInputTextField
                onChange={(e) => setNotes(e.target.value)}
                label='notes'
                type="text"
                value={notes}
                required={false}
                rows={6}
            />


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WorkLogsForm;