import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ITask} from "../../../../../../../utils/interfaces/my_projects/ITask";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetTask = (pk: number | undefined): UseQueryResult<ITask, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<ITask> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_projects/tasks/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetTask", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk,
    })

};

export default useApiGetTask