import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IWorkLog} from "../../../../../../../utils/interfaces/my_projects/IWorkLog";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetWorkLogs = (day: string | undefined): UseQueryResult<IWorkLog[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IWorkLog[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_projects/work_logs?day=${day}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWorkLogs", day],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetWorkLogs