import React from 'react';
import ProjectsTable from "./ProjectsTable";
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import MyProjectsScreen from "../MyProjectsScreen";

type Props = {
    children?: React.ReactNode,
}

const ProjectsScreen = ({children}: Props) => {

    return (
        <>
            <MyProjectsScreen>
                <MaxWidthDiv maxWidth='1024px'>
                    <ProjectsTable/>
                </MaxWidthDiv>
            </MyProjectsScreen>


        </>
    );
};

export default ProjectsScreen;