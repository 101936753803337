import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    RaspberryPiPin
} from "../../../../../../../../../utils/interfaces/industry_machine/RaspberryPiPinLog/RaspberryPiPin";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../../utils/contexts/auth-context";


const useApiGetRaspberryPiIpPinLog = (ip: string | undefined, pin: number | undefined): UseQueryResult<RaspberryPiPin[], CustomAxiosError> => {
    const {get} = useApiCalls()
    const {selectedCompany} = useAuth()

    async function doGet(): Promise<RaspberryPiPin[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/industry_machine/raspberrypi_pin_log/list/?raspberry_pi_ip=${ip}&pin_number=${pin}`);
            return response.data

        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRaspberryPiIpPinLog"],
        queryFn: () => doGet(),
        enabled: !!ip && !!pin,
    })
};


export default useApiGetRaspberryPiIpPinLog