import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import MyProjectsScreen from "../MyProjectsScreen";
import DailyWorkLogsTotalsTable from "./DailyWorkLogsTotalsTable";

type Props = {
    children?: React.ReactNode,
}

const DailyWorkLogsTotalsScreen = ({children}: Props) => {

    return (
        <>
            <MyProjectsScreen>
                <MaxWidthDiv maxWidth='1024px'>
                    <DailyWorkLogsTotalsTable/>
                </MaxWidthDiv>
            </MyProjectsScreen>


        </>
    );
};

export default DailyWorkLogsTotalsScreen;