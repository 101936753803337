import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {ITask} from "../../../../../../../utils/interfaces/my_projects/ITask";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiCreateProjectTask = (project_id: number | undefined): UseMutationResult<ITask, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<ITask> {
        try {
            const response = await post(`/api/company/${companyToken}/my_projects/projects/${project_id}/tasks`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<ITask, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },

        }
    );
};


export default useApiCreateProjectTask;