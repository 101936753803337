import React from 'react';
import MaxWidthDiv from "../../../ui/MaxWitdthDiv";


const IndustryMachineScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>

            </MaxWidthDiv>


        </>
    );
};

export default IndustryMachineScreen;
