import React, {useEffect, useState} from 'react';
import NavBar from "./NavBar";
import {AppBar, Box, Toolbar} from "@mui/material";
import {IconContext} from "react-icons";
import IconButton from "@mui/material/IconButton";
import {TiThMenu} from "react-icons/ti";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../../../utils/contexts/auth-context";
import banner from "../../../../assets/company_banner.png";
import {BiSolidFactory} from "react-icons/bi";
import {FaUser} from "react-icons/fa";
import LanguageSelect from "../../../../utils/translation/LanguageSelect";
import useApiUser from "../../../../utils/custom_hooks/useApiUser";
import {motion} from "framer-motion";
import MaxWidthDiv from "../../MaxWitdthDiv";
import CompanySelection from "../../../app/Home/CompanySelection";
import {formatDateTimeToDateString} from "../../../../logics/helpers";
import {useAppGarage} from "../../../../utils/contexts/appGarage-context";
import {useTranslation} from "react-i18next";
import MuiAppBarButton from "../../mui/mui_buttons/MuiAppBarButton";
import MuiModal from "../../mui/mui_modal/MuiModal";
import SystemDateSelector from "../../../app/Home/SystemDateSelector";
import useApiGetUserLevel from "../../../../utils/custom_hooks/useApiGetUserLevel";
import usePostLogin from "../../../Login/api-hooks/usePostLogin";
import {CustomAxiosError} from "../../../../logics/api_config";
import {useToast} from "../../../../utils/contexts/toast-context";
import useApiGetRentriToken from "../../../app/apps/special_wasty/rentri/api-hooks/useApiGetRentriToken";


type Props = {
    title?: string,
}

const Layout = ({title}: Props) => {
    const {t} = useTranslation()
    const {displayError, displayMessage} = useToast()
    const navigate = useNavigate();
    const {
        isAuthenticated,
        selectedCompany,
        authenticatedUser,
        loadSelectedCompany,
        loadAvailableCompanies,
        loadAuthenticatedUser,
        setIsAuthenticated,
        loadUserLevel, endpoint
    } = useAuth();
    const {selectedSystemDate, selectSystemDate, selectSystemYear} = useAppGarage()
    const [isNavBarOpen, setIsNavBarOpen] = useState<boolean>(false);
    const [systemDateSelectorOpen, setSystemDateSelectorOpen] = useState(false)
    const {setEndpoint, user, password} = useAuth()

    const handleOpenNavBar = () => {
        setIsNavBarOpen(true)
    }

    const handleCloseNavBar = () => {
        setIsNavBarOpen(false)
    }

    const handleToggleNavBar = () => {
        setIsNavBarOpen(!isNavBarOpen)
    }

    // prefetch the token for avoid errors in fetching external data
    const {data: rentri_token} = useApiGetRentriToken()

    const {data: user_level} = useApiGetUserLevel()
    useEffect(() => {
        loadUserLevel(0)
        if (user_level) {
            loadUserLevel(user_level.user_level)
        }
    }, [user_level]);

    // silent re-sign in with the new endpoint
    const loginMutation = usePostLogin();
    const re_signin = async (username: string, password: string) => {
        try {
            const response = await loginMutation.mutateAsync({
                username: username,
                password: password,
            });
            if (response) {
                localStorage.setItem('@access_token', response.access);
                localStorage.setItem('@refresh_token', response.refresh);
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response.data.detail) {
            }
            displayError("Error in re-signin");
            setEndpoint(process.env.REACT_APP_ENDPOINT);
            navigate('/login');
        }
    }


    const {data} = useApiUser()
    useEffect(() => {
        loadSelectedCompany(undefined);
        const storedCompanyToken = localStorage.getItem('@company_token');

        const system_date = localStorage.getItem('@system_date');
        if (system_date) {
            selectSystemDate(system_date);
        }

        const system_year = localStorage.getItem('@system_year');
        if (system_year) {
            selectSystemYear(parseInt(system_year));
        }


        if (data) {
            setIsAuthenticated(true);
            loadAvailableCompanies(data.companies);
            loadAuthenticatedUser(data);

            if (storedCompanyToken) {
                const storedCompany = data.companies.find(company => company.company_token === storedCompanyToken);
                if (storedCompany) {
                    loadSelectedCompany(storedCompany);
                }
            }

            if (data.companies.length === 1) {
                localStorage.setItem('@company_token', selectedCompany ? selectedCompany.company_token : '');
                loadSelectedCompany(data.companies[0]);
            }
        }
    }, [data]);

    useEffect(() => {
        if (user && password) {
            re_signin(user, password)
        }
    }, [endpoint]);

    const location = useLocation();
    // console.log('Current Location:', location);
    // console.log('Outlet Content:', <Outlet/>);
    // console.log('isAuthenticated:', isAuthenticated);
    // console.log('selectedCompany:', selectedCompany);


    return (
        <>
            {isAuthenticated && selectedCompany ?
                <>
                    <>
                        <NavBar
                            isOpen={isNavBarOpen}
                            handleOpen={handleOpenNavBar}
                            handleClose={handleCloseNavBar}
                            handleToggle={handleToggleNavBar}
                        />

                        <motion.div
                            className="flex items-center justify-between"
                            initial={{height: 0}}
                            animate={{height: 64}}
                            transition={{duration: 0.5}}
                        >
                            <AppBar>
                                <IconContext.Provider
                                    value={{color: "white", size: "20px"}}
                                >
                                    <Toolbar
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <IconButton
                                            onClick={handleToggleNavBar}
                                            // onMouseOver={handleOpenNavBar}
                                            sx={{}}
                                        >
                                            <TiThMenu/>
                                        </IconButton>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <img src={banner} alt="" width="100" height="80"
                                                 style={{marginRight: 20}}/>
                                            <Box className="text-white font-medium text-xs flex flex-row"
                                                 style={{marginRight: 20}}>
                                                <BiSolidFactory style={{fontSize: '1rem', marginRight: 0.5}}/>
                                                {selectedCompany.company_name}
                                            </Box>
                                            <Box className="text-white font-medium text-xs flex flex-row ml-2"
                                                 style={{marginRight: 20}}>
                                                <FaUser style={{fontSize: '1rem', margin: '0 0.5'}}/>
                                                {authenticatedUser?.username}
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Box sx={{marginRight: 2}}>
                                                <LanguageSelect/>
                                            </Box>
                                            <Box sx={{whiteSpace: 'nowrap'}}>
                                                <MuiAppBarButton onClick={() => setSystemDateSelectorOpen(true)}>
                                                    {formatDateTimeToDateString(selectedSystemDate)}
                                                </MuiAppBarButton>
                                            </Box>
                                        </Box>
                                    </Toolbar>
                                </IconContext.Provider>
                            </AppBar>
                        </motion.div>
                    </>


                    <Box className={'mx-2'}>
                        <Outlet/>
                    </Box>


                </>
                :
                isAuthenticated ?
                    <Box>
                        <MaxWidthDiv maxWidth='1024px'>
                            <CompanySelection/>
                        </MaxWidthDiv>
                    </Box>
                    :
                    null
            }
            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={systemDateSelectorOpen}
                onClose={() => setSystemDateSelectorOpen(false)}
            >
                <SystemDateSelector onClose={() => setSystemDateSelectorOpen(false)}/>
            </MuiModal>

        </>
    );
};

export default Layout;