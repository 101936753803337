import React from 'react';
import {Box} from "@mui/material";
import MaxWidthDiv from "../../../ui/MaxWitdthDiv";
import {FaProjectDiagram} from "react-icons/fa";
import {useLocation, useNavigate} from "react-router-dom";
import MuiCustomSpeedDial from "../../../ui/mui/MuiCustomSpeedDial";
import {IoHourglassOutline} from "react-icons/io5";

type Props = {
    children?: React.ReactNode;
};

const MyProjectsScreen = ({children}: Props) => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const actions = [
        {
            icon: <FaProjectDiagram/>,
            name: "Progetti",
            onClick: () => {
                navigate("/apps/my_projects/projects");
            },
            isPulsing: false,
        },
        {
            icon: <IoHourglassOutline/>,
            name: "Work Logs",
            onClick: () => {
                navigate("/apps/my_projects/work_logs");
            },
            isPulsing: false,
        },
    ];

    return (
        <>
            <MaxWidthDiv maxWidth="2500px">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                        position: "relative",
                    }}
                ></Box>

                <Box sx={{height: 320, transform: "translateZ(0px)", flexGrow: 1}}>
                    {children}
                </Box>
            </MaxWidthDiv>

            <MuiCustomSpeedDial
                actions={actions}
                customIcon={<FaProjectDiagram style={{fontSize: 30}}/>}
            />
        </>
    );
};

export default MyProjectsScreen;