import React from 'react';
import MaxWidthDiv from "../../../../../ui/MaxWitdthDiv";
import RaspberryPiIpTable from "./RaspberryPiIpTable";


const RaspberryPiIpScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <RaspberryPiIpTable/>
            </MaxWidthDiv>


        </>
    );
};

export default RaspberryPiIpScreen;
