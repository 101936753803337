import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IProject, IProjectCHILD} from "../../../../../utils/interfaces/my_projects/IProject";
import useApiCreateProject from "./api-hooks/useApiCreateProject";
import useApiUpdateProject from "./api-hooks/useApiUpdateProject";
import useApiGetProject from "./api-hooks/useApiGetProject";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import PeopleSelect from "../../service/people/PeopleSelect";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {getCurrentDate} from "../../../../../logics/helpers";


interface Props {
    objToEdit: IProject | IProjectCHILD | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IProject | undefined) => void,
}

const ProjectForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth();

    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [people, setPeople] = useState<IPeople>()
    const [createdAt, setCreatedAt] = useState<string>(getCurrentDate());
    const [closedAt, setClosedAt] = useState<string>();

    const createMutation = useApiCreateProject();
    const updateMutation = useApiUpdateProject();

    const {
        data: detailedObj,
    } = useApiGetProject(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj.name);
            setPeople(detailedObj.people)
            setDescription(detailedObj.description)
            setCreatedAt(detailedObj.created_at)
            setClosedAt(detailedObj.closed_at)
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                company_id: selectedCompany?.id,
                name: name,
                description: description,
                people_id: people?.id,
                closed_at: closedAt ? closedAt : null,
                created_at: createdAt ? createdAt : null,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ['useApiGetProject', newData?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetProjects"]});

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>
            <MuiInputTextField
                onChange={(e) => setName(e.target.value)}
                label='name'
                type="text"
                value={name}
                required={true}
            />
            <MuiInputTextField
                onChange={(e) => setDescription(e.target.value)}
                label='description'
                type="text"
                value={description}
                rows={4}
                required={false}
            />

            <Box className="grid grid-cols-2 gap-4">
                <MuiInputTextField
                    onChange={setCreatedAt}
                    label='created at'
                    type="date"
                    value={createdAt}
                    required={true}

                />
                <MuiInputTextField
                    onChange={setClosedAt}
                    label='closed at'
                    type="date"
                    value={closedAt}
                    required={true}
                />

            </Box>

            <PeopleSelect
                label={'Customer'}
                people={people}
                onChange={(obj) => {
                    obj && setPeople(obj)
                }}
                className={'text-black mb-2 mr-5'}
                category_person={'customer'}
            />


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default ProjectForm;