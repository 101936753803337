import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import React, {useEffect, useState} from "react";
import useApiGetPeople from "./api-hooks/useApiGetPeople";
import {CustomAxiosError} from "../../../../../logics/api_config";
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import useApiGetPersonDetails from "./api-hooks/useApiGetPersonDetails";
import {Add, Edit} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import PeopleForm from "./PeopleForm";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import PeopleTable from "./PeopleTable";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";


interface Props {
    people: IPeople | undefined,
    onChange: (person: IPeople | null) => void,
    className: string,
    category_person?: string,
    label?: string,
    required?: boolean
    isDisabled?: boolean
}

const PeopleSelect = ({people: initialObj, onChange, category_person, label, required, isDisabled = false}: Props) => {
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation();
    const {selectedCompany} = useAuth()
    const [objs, setObjs] = useState<IPeople[]>([]);
    const [obj, setObj] = useState<IPeople | null | undefined>(initialObj);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)

    const {
        data: detailedObj,
    } = useApiGetPersonDetails(selectedCompany?.company_token, obj?.id);


    const {
        data,
        isLoading,
        error,
    } = useApiGetPeople(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);

    const handleChange = (obj: IPeople | undefined) => {
        setObj(obj);
        onChange(obj || null);
    };


    useEffect(() => {
        if (data) {
            if (category_person) {
                const filteredData = data.filter(item => {
                    return item.peoplecategory_set.some(category => category.category_name === category_person);
                });
                setObjs(filteredData);
            } else {
                setObjs(data)
            }
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IPeople | null) => {
        onChange(newValue);
    };


    return (
        isLoading ? (
            <Loading/>
        ) : (
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        isDisabled={isDisabled}
                        id="people-select"
                        options={objs}
                        getOptionLabel={(option) => option.people_name}
                        value={detailedObj ? detailedObj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.id === value?.id && option.people_name === value?.people_name}
                        renderInput={(params) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label || t('people')}
                                required={required}
                            />
                        )}
                    >
                        <MRTMuiButton
                            icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => setIsListTableOpen(true)}
                            small={true}
                            label={'show_list'}
                        />
                        <MRTMuiButton
                            isDisabled={isDisabled}
                            small={true}
                            icon={obj ?
                                <Edit
                                    sx={{color: isDisabled ? theme.palette.disabledColor : theme.palette.primary.main}}/>
                                :
                                <Add
                                    sx={{color: isDisabled ? theme.palette.disabledColor : theme.palette.primary.main}}/>}
                            onClick={() => {
                                setIsEditFormOpen(true)
                            }} label={obj ? 'edit_people' : 'add_people'}/>
                    </MuiAutocomplete>
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80%'}
                    // height={'80%'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <PeopleForm personToEdit={obj ? obj : null}
                                category_person={'customers'}
                                onClose={() => setIsEditFormOpen(false)}/>
                </MuiModal>

                <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                          onClose={() => setIsListTableOpen(false)}
                          disableBackdropClick={true}
                >
                    <PeopleTable
                        onSelect={handleChange}
                        category_person={'customer'}
                        onClose={() => setIsListTableOpen(false)}
                    />
                </MuiModal>
            </>
        )
    );
}

export default PeopleSelect;