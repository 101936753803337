import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ITask} from "../../../../../../../utils/interfaces/my_projects/ITask";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetProjectTasks = (project_id: number | undefined): UseQueryResult<ITask[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<ITask[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_projects/projects/${project_id}/tasks`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetProjectTasks", project_id],
        queryFn: () => doGet(selectedCompany?.company_token,),
        enabled: !!project_id,
    })
};

export default useApiGetProjectTasks