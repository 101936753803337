import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {useQueryClient} from "@tanstack/react-query";
import {IWorkLog} from "../../../../../../utils/interfaces/my_projects/IWorkLog";
import useApiDeleteWorkLog from "./api-hooks/useApiDeleteWorkLog";
import useApiGetWorkLogs from "./api-hooks/useApiGetWorkLogs";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {IoHourglassOutline} from "react-icons/io5";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../../ui/Loading";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import WorkLogsForm from "./WorkLogsForm";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";


interface Props {
    onSelect?: (obj: IWorkLog | undefined) => void,
    onClose?: () => void;
    day: string;
}


const WorkLogsTable = ({onSelect, onClose, day}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IWorkLog | undefined>()
    const [rows, setRows] = useState<IWorkLog[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWorkLog(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;


    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetWorkLog', selectedRow?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWorkLogs", day]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetDailyWorkLogsTotals"]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetWorkLogs(day);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IWorkLog, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IWorkLog>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWorkLog>[] = [
                // {
                //     id: "icons",
                //     header: "ICONS",
                //     columnDefType: "display",
                //     Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
                // },
                {
                    accessorKey: 'task.project.people.people_name',
                    header: t('customer'),
                },
                {
                    accessorKey: 'task.project.name',
                    header: t('project'),
                },
                {
                    accessorKey: 'task.name',
                    header: t('task'),
                },
                {
                    accessorKey: 'hours_spent',
                    header: t('hours spent'),
                },
                {
                    accessorKey: 'notes',
                    header: t('notes'),
                },

            ];

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWorkLog>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <IoHourglassOutline color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'Work Logs'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        ),

    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >
                    <WorkLogsForm
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WorkLogsTable;
