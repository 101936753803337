import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IProject} from "../../../../../../utils/interfaces/my_projects/IProject";
import {IPeople} from "../../../../../../utils/interfaces/service/people/IPeople";


const useApiGetProjects = (person: IPeople | undefined): UseQueryResult<IProject[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IProject[]> {
        try {
            const url = person?.id
                ? `/api/company/${company_token}/my_projects/projects?person=${person.id}`
                : `/api/company/${company_token}/my_projects/projects`;
            const response = await get(url);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetProjects", person?.id],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetProjects