import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IProject} from "../../../../../../utils/interfaces/my_projects/IProject";


const useApiCreateProject = (): UseMutationResult<IProject, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IProject> {
        try {
            const response = await post(`/api/company/${companyToken}/my_projects/projects`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IProject, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },

        }
    );
};


export default useApiCreateProject;