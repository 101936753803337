import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IRaspberryPiIp} from "../../../../../../../utils/interfaces/industry_machine/RaspberryPiPinLog/IRaspberryPiIp";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetRaspberryPiIps = (): UseQueryResult<IRaspberryPiIp[], CustomAxiosError> => {
    const {get} = useApiCalls()
    const {selectedCompany} = useAuth()

    async function doGet(): Promise<IRaspberryPiIp[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/industry_machine/raspberrypi_pin_log/raspberry_pi_ip/`);
            return response.data

        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRaspberryPiIps"],
        queryFn: () => doGet(),
        retry: 1
    })
};


export default useApiGetRaspberryPiIps