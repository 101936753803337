import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {FaCircle} from "react-icons/fa";
import {RaspberryPiPin} from "../../../../../../../utils/interfaces/industry_machine/RaspberryPiPinLog/RaspberryPiPin";

const useCustomCellRenderer = (row: RaspberryPiPin, theme: any) => {


    const getIconsForCustomField = (row: RaspberryPiPin, theme: any) => {
        const iconColor = theme.palette.primary.main;

        return (
            <FaCircle color={row.pin_value === 0 ? 'green' : 'red'}/>

        );
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;